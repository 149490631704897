import { useTranslation } from 'react-i18next'
import Body from '#app/components/typography/body'
import Heading from '#app/components/typography/heading'
import { Icon } from './ui/icon'

export const WhyShopHere = () => {
	let { t } = useTranslation('footer')
	const getIconComponent = (name: any) => {
		return <Icon name={name} size="lg" className="text-white" />
	}

	const icons = [
		{
			icon: getIconComponent('cart-white'),
			heading: t('shop_online_or_offline_header'),
			text: t('shop_online_or_offline_body'),
		},
		{
			icon: getIconComponent('clock'),
			heading: t('save_time_header'),
			text: t('save_time_body'),
		},
		{
			icon: getIconComponent('pickup'),
			heading: t('fast_delivery_header'),
			text: t('fast_delivery_body'),
		},
		{
			icon: getIconComponent('return-arrow'),
			heading: t('easy_returns_header'),
			text: t('easy_returns_body'),
		},
	]

	return (
		<div className="w-full bg-black py-8 md:py-10">
			<div className="mx-auto flex flex-col justify-between gap-8 px-4 md:flex-row md:px-[var(--plenty-padding)]">
				{icons.map((item, index) => (
					<div key={index} className="flex flex-col items-center">
						{item.icon}
						<Heading
							as="h7"
							heading={item.heading}
							textColor="white"
							className="color-white text-[24px]"
						/>
						<Body as="m" body={item.text} textColor="white" />
					</div>
				))}
			</div>
		</div>
	)
}
