import { Link } from '@remix-run/react'
import { useTranslation } from 'react-i18next'
import Body from '#app/components/typography/body'
import Heading from '#app/components/typography/heading'
import { Icon } from '#app/components/ui/icon'
import LanguageModal from '#app/features/localisation/language-modal'

export function Footer() {
	let { t } = useTranslation('footer')

	return (
		<footer className=" m-0 mx-auto">
			<div className="flex flex-col justify-between text-black-90">
				<div className="bg-beige-50 bg-ampersandFooter bg-center bg-no-repeat md:bg-desktop-footer ">
					<div className="mx-auto flex flex-col justify-start gap-8 px-4 pt-14 md:flex-row md:justify-between md:space-x-8 md:px-[var(--plenty-padding)]">
						<div className=" flex flex-col gap-2">
							<div>
								<Heading
									as={'h7'}
									heading={'Plenty&'}
									className="text-[24px]"
								/>
							</div>
							<div className="flex max-w-full flex-col gap-2 md:max-w-[300px]">
								<Body as={'m'} body={t('tagline')} />
							</div>
						</div>
						<div className="flex flex-col gap-4">
							<div>
								<Heading
									as={'h7'}
									heading={t('about_us')}
									className="text-[24px]"
								/>
							</div>
							<div className="flex  flex-col gap-2">
								<Link to={'/about-us'} className="group w-fit cursor-pointer">
									<Body
										as={'m'}
										body={t('our_story')}
										className="border-b border-b-transparent group-hover:border-black-90 group-focus:border-mint-green-50"
									/>
								</Link>
								<Link to={'/stores'} className="group w-fit cursor-pointer">
									<Body
										as={'m'}
										className="border-b border-b-transparent group-hover:border-black-90 group-focus:border-mint-green-50"
										body={t('find_a_store')}
									/>
								</Link>
								<Link to={'/brands'} className="group w-fit cursor-pointer">
									<Body
										as={'m'}
										className="border-b border-b-transparent group-hover:border-black-90 group-focus:border-mint-green-50"
										body={t('&_brands')}
									/>
								</Link>
							</div>
						</div>
						<div className="flex flex-col gap-4">
							<div>
								<Heading
									as={'h7'}
									heading={t('get_help')}
									className="text-[24px]"
								/>
							</div>
							<div className="flex  flex-col gap-2">
								<Link to={'/faq'} className="group w-fit cursor-pointer">
									<Body
										as={'m'}
										body={t('faq')}
										className="border-b border-b-transparent group-hover:border-black-90 group-focus:border-mint-green-50"
									/>
								</Link>
								<Link
									to={'/click-and-collect-and-delivery'}
									className="group w-fit cursor-pointer"
								>
									<Body
										as={'m'}
										body={t('shipping_&_delivery')}
										className="border-b border-b-transparent group-hover:border-black-90 group-focus:border-mint-green-50"
									/>
								</Link>
								<Link
									to={'/returns-and-refunds'}
									className="group w-fit cursor-pointer"
								>
									<Body
										as={'m'}
										className="border-b border-b-transparent group-hover:border-black-90 group-focus:border-mint-green-50"
										body={t('returns_&_refunds')}
									/>
								</Link>
							</div>
						</div>
						<div className="flex flex-col gap-4">
							<div>
								<Heading
									as={'h7'}
									heading={t('follow_us')}
									className="text-[24px]"
								/>
							</div>
							<div className="flex  flex-col gap-2">
								<a
									href="https://www.facebook.com/profile.php?id=100089802061958"
									target="_blank"
									rel="noopener noreferrer"
									className="group flex items-center gap-2"
								>
									<Icon className="text-body-md" size="sm" name="facebook" />
									<Body
										as="m"
										body="Facebook"
										className="border-b border-b-transparent group-hover:border-black-90 group-focus:border-mint-green-50"
									/>
								</a>
								<a
									href="https://www.instagram.com/plentyand_/"
									target="_blank"
									rel="noopener noreferrer"
									className="group flex items-center gap-2"
								>
									<Icon className="text-body-md" size="sm" name="instagram" />
									<Body
										as="m"
										body="Instagram"
										className="border-b border-b-transparent group-hover:border-black-90 group-focus:border-mint-green-50"
									/>
								</a>
								<a
									href="https://www.linkedin.com/company/plentyand/"
									target="_blank"
									rel="noopener noreferrer"
									className="group flex items-center gap-2"
								>
									<Icon className="text-body-md" size="sm" name="linkedin" />
									<Body
										as="m"
										body="LinkedIn"
										className="border-b border-b-transparent group-hover:border-black-90 group-focus:border-mint-green-50"
									/>
								</a>
								<a
									href="https://www.pinterest.dk/plentyand/"
									target="_blank"
									rel="noopener noreferrer"
									className="group flex items-center gap-2"
								>
									<Icon className="text-body-md" size="sm" name="pinterest" />
									<Body
										as="m"
										body="Pinterest"
										className="border-b border-b-transparent group-hover:border-black-90 group-focus:border-mint-green-50"
									/>
								</a>
							</div>
						</div>
						<div className="flex flex-col gap-4">
							<div>
								<Heading
									as={'h7'}
									heading={t('contact', { ns: 'common' })}
									className="text-[24px]"
								/>
							</div>
							<div className="flex  flex-col gap-2">
								<Body as={'m'} body={'Plenty&'} />
								<Body as={'m'} body={'Store Torv 1, 8000 Aarhus'} />
								<Body as={'m'} body={'Support@plentyand.dk'} />
								<Body as={'m'} body={'+45 82 38 25 50'} />
							</div>
						</div>
					</div>
					<div className="flex justify-start space-x-2 px-4 pb-14 pt-8 md:flex-row md:justify-end md:space-x-8 md:px-[var(--plenty-padding)] md:pb-6 md:pt-4">
						<Icon className="text-body-md h-10 w-[60px]" name="mobile-pay" />
						<Icon className="text-body-md h-10 w-[60px]" name="debit-card" />
						<Icon className="text-body-md h-10 w-[60px]" name="visa" />
						<Icon className="text-body-md h-10 w-[60px]" name="master-card" />
					</div>
				</div>

				<div className="flex flex-col justify-between gap-4 px-4 py-4 md:flex-row md:px-[var(--plenty-padding)] md:py-4">
					<div className="md:justify-auto flex justify-between gap-6">
						<div className="hidden gap-2 md:block ">
							<LanguageModal />
						</div>
						<div>
							<Body as={'s'} body={'Plenty&'} />
						</div>
						<div>
							<Body
								as={'s'}
								body={`© ${new Date().getFullYear()} All Rights Reserved`}
							/>
						</div>
					</div>
					<div className="flex justify-between md:gap-10">
						<div>
							<Link
								to="/policy/terms-and-condition"
								className="group w-fit cursor-pointer"
							>
								<Body
									as={'s'}
									className="border-b border-b-transparent group-hover:border-black-90 group-focus:border-mint-green-50"
									body={t('terms_&_conditions')}
								/>
							</Link>
						</div>
						<div>|</div>
						<div>
							<Link to="/policy/privacy" className="group w-fit cursor-pointer">
								<Body
									as={'s'}
									className="border-b border-b-transparent group-hover:border-black-90 group-focus:border-mint-green-50"
									body={t('privacy_policy')}
								/>
							</Link>
						</div>
						<div>|</div>
						<div>
							<Link
								to={'/policy/cookie'}
								className="group w-fit cursor-pointer"
							>
								<Body
									className="border-b border-b-transparent group-hover:border-black-90 group-focus:border-mint-green-50"
									as={'s'}
									body={'Cookies'}
								/>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</footer>
	)
}
